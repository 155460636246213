<template>
  <div>
    <Grid :key="key"
          :service="service"
          route_name="practical_tests">

      <template v-slot:actions="data">
        <feather-icon icon="EyeIcon" svgClasses="w-6 h-6 text-primary" @click="openDashboard(data)"/>
      </template>
    </Grid>
  </div>
</template>

<script>
import PracticalTestService from '@/services/api/PracticalTestService'

export default {
  data: () => ({
    selected: [],
    service: null,
    row: null
  }),
  methods: {
    openDashboard(data) {
      console.log('row...', data)
      this.$router.push(`/practical_tests/${data.row.id}/dashboard`)
    }
  },
  beforeMount() {
    this.service = PracticalTestService.build(this.$vs)
  }
}
</script>
