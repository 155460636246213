var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Grid", {
        key: _vm.key,
        attrs: { service: _vm.service, route_name: "practical_tests" },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function (data) {
              return [
                _c("feather-icon", {
                  attrs: {
                    icon: "EyeIcon",
                    svgClasses: "w-6 h-6 text-primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.openDashboard(data)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }